export const environment = {
	production: false,
	env: 'hml',
	timer: 10,
	version: '16.0.20851',
	produtoId: 'dc35e97d-5513-416a-81c1-e44b58ea6d79',
	oatFactor: 'ygt2sectwggttl6czlym5vwyzob2fz3vfyicv67uxiwhiwotb75q',

	// business api
	baseURL: 'https://apihmz.bancobonsucesso.com.br/pj/empresas/forfrontend/spa/v1',
	baseURLIb: 'https://apihmz.bancobonsucesso.com.br/pj/banking/forfrontend/spa/v1',
	baseURLPix: 'https://apihmz.bancobonsucesso.com.br',
	baseURLQrCode: 'https://apihmz.bancobonsucesso.com.br/pj/empresas/qrcodes/forfrontend/spa/v1',

	// Keys
	recaptchaSiteKey: '6LdIRMAUAAAAAP7FobM9cEnR5zYHP6_eJpL_IC-c',

	// Bytes
	maxFileSize: 5242880, 
};
