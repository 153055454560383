import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
	Bs2ComponentsModule,
	GTAG_GROUP,
	LegalDocumentPipe,
	PartnerInterceptor,
	PartnersEnum,
	TRANSLATE,
	TelefonePipe,
	TranslateModel,
} from '@bs2/utilities-v9';
import localePt from '@angular/common/locales/pt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { registerLocaleData } from '@angular/common';
import { LocalhostRequestInterceptor } from './core/interceptors/localhost-request.interceptor';
import { environment } from 'src/environments/environment';
import { UnauthenticatedTokenInterceptor } from './core/interceptors/unauthenticated-token.interceptor';


registerLocaleData(localePt);

const translate: TranslateModel = {
	defaltLang: 'pt-br',
	i18nPath: 'assets/i18n/',
};
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		MatIconModule,
		BrowserModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		Bs2ComponentsModule.forRoot({
			env: environment.env,
			appVersion: environment.version,
			produtoId: environment.produtoId,
			appType:'aplicacoes',
			partner: PartnersEnum.BS2,
			oatFactor: environment.oatFactor
		}),
		SharedModule,
		HttpClientModule,
		MaterialModule,
	],
	providers: [
		{ provide: GTAG_GROUP, useValue: 'wac_hmg' },
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: TRANSLATE, useValue: translate },
		{ provide: HTTP_INTERCEPTORS, useClass: PartnerInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LocalhostRequestInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: UnauthenticatedTokenInterceptor, multi: true },

		LegalDocumentPipe,
		TelefonePipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
